import Image from 'next/image';

function BrandLogos({ children }) {
  return (
    <div className="bg-white relative">
      <div className="p-5 pt-3 max-w-2xl m-auto">
        <div className="text-[#060D2166] text-center sm:text-xl max-w-2xl text-md font-medium mt-4">
          <div className="mt-4">{children}</div>
        </div>
        <Image
          src="/brands3.png"
          priority={true}
          className="mt-3 hidden sm:block"
          alt="Companies that work with SnapLuna"
          width={700}
          height={200}
        />
        <Image
          src="/brands1.png"
          className="mt-3 pl-5 pr-5 block sm:hidden"
          alt="Companies that use AI Interior Design with SnapLuna"
          priority={true}
          width={700}
          height={200}
        />
        <Image
          src="/brands2.png"
          className="mt-3 pl-5 pr-5 block sm:hidden"
          alt="Companies that use AI Interior Design with SnapLuna"
          priority={true}
          width={700}
          height={200}
        />
      </div>
    </div>
  );
}

export default BrandLogos;

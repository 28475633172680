import Image from 'next/image';
import React from 'react';
import { GradientPhoto } from './GradientPhoto';
import { GradientClothing } from './GradientClothing';

export const ImageGallery = ({ xkey, translator }) => {
  return (
    <div
      className="relative w-full  h-[410px] md:h-[820px] ml-[12vw] md:ml-0  "
      key={xkey}
    >
      <div className="bg-slate-200 shadow blur-[60.40px]" />
      <div className="">
        <div className="bg-neutral-100 h-[15vw] w-[22vw] min-w-[170px]  min-h-[120px] max-w-[270px] max-h-[180px] absolute bottom-[calc(19%*1)]  shadow-xl flex-col justify-center items-center gap-[21.90px] flex rounded-md border-dashed border-4 border-neutral-300 ">
          <div className="   flex-col justify-center items-center gap-2 md:gap-[21.90px] flex ">
            <div className="">
              <GradientClothing xkey={xkey} className="h-16 " />
            </div>

            <div className="self-stretch h-[18px] flex-col justify-center items-center flex">
              <div className="self-stretch text-center">
                <span className="text-zinc-500 text-xs md:text-sm font-bold">
                  {translator('yroom')}
                </span>
                {/* <span className="gradient-text text-xs md:text-sm font-bold">
                  browse
                </span> */}
              </div>
            </div>
          </div>
        </div>
        <Image
          className="object-cover rounded-lg h-[25vw] w-[30vw] min-w-[170px]  min-h-[120px] max-w-[340px] max-h-[239px] drop-shadow-sm shadow-2xl border-2 border-white absolute bottom-[calc(19%*2)] left-[25vw] md:left-60"
          src="https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/5936e9f2-666f-4458-d87f-1de6afc2a500/alldat"
          width={339}
          height={200}
        />
        <Image
          className="object-cover  rounded-lg  h-[25vw] w-[30vw] min-w-[170px]  min-h-[120px] max-w-[340px] max-h-[239px] drop-shadow-sm shadow-2xl border-2 border-white absolute bottom-[calc(19%*3)] left-0"
          src="https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/5b70e2f6-a3ad-4c95-2799-798d98d16000/alldat"
          width={339}
          height={200}
        />
        <Image
          className="object-cover  rounded-lg  h-[25vw] w-[30vw] min-w-[170px]  min-h-[120px] max-w-[340px] max-h-[239px] drop-shadow-sm shadow-2xl border-2 border-white absolute left-[25vw] bottom-[calc(19%*4)] md:left-60"
          src="https://imagedelivery.net/_KeVhvb2O4eJIzSpK4wEFQ/74c2d694-e241-44b8-3548-31353dc5a900/alldat"
          width={339}
          height={200}
        />
        {/* <Image
          className="rounded-lg drop-shadow-sm shadow-2xl border-2 border-white"
          src="/hero4.jpg"
          width={339}
          height={200}
        /> */}
        {/* <Image
          className="rounded-lg drop-shadow-sm shadow-2xl border-2 border-white"
          src="/hero5.jpg"
          width={339}
          height={200}
        /> */}
      </div>
    </div>
  );
};

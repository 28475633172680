import { Disclosure } from '@headlessui/react';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
const faqs = [
  {
    key: 'wergsdg1234',
    question: 'What is Snapluna?',
    answer: `Snapluna is an innovative fashion visualization app, leveraging the power of Artificial Intelligence to instantly show how different outfits will look on you. By simply uploading a photo, Snapluna can visualize you in various styles, providing quick and exciting alternatives. It's an invaluable tool for fashion designers, retailers, and style enthusiasts, helping to streamline the outfit selection process and save hours of time.`
  },
  {
    key: 'bgrhert1234',
    question:
      "Can I use Snapluna even if I'm not a professional fashion designer?",
    answer:
      'Absolutely! Snapluna is designed for everyone - from professional fashion designers to casual style enthusiasts. Our user-friendly interface makes it easy to visualize new outfits, no fashion experience required!'
  },
  {
    key: 'cgewrgg1234',
    question: 'Is there a cost to use Snapluna?',
    answer: `Snapluna provides 3 free outfit visualizations for you to try our service. Beyond these initial trials, we offer a variety of premium options to continue enjoying our vast selection of styles and other exclusive features.`
  },
  {
    key: 'fdsgd1234',
    question: 'Can I use Snapluna for Virtual Outfit Staging?',
    answer:
      '<p>Snapluna uses an extremely advanced AI that has been optimized for visualizing outfits on different body types. It can show how various garments and accessories will look, making it the perfect virtual styling solution.</p>'
  },
  {
    key: 'easd1234',
    question: 'What types of fashion styles does Snapluna offer?',
    answer:
      "Snapluna offers a wide array of fashion styles, from modern and minimalist to traditional and extravagant. Whether you're looking to completely revamp your wardrobe or just seek some fresh inspiration, we've got you covered!"
  },
  {
    key: 'newkey1234',
    question: 'Does Snapluna guarantee the fit of clothes?',
    answer:
      'While Snapluna can visualize how clothes will look on you, it does not guarantee the fit. Our tool helps you see various styles and outfits on your body type, but always check size guides and specific measurements from the retailer before purchasing.'
  }
  // More questions...
];

export default function FaqHome() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl lg:px-0">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          {/* <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2> */}
          <dl className="mt-0 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <div>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-medium leading-7 text-[#4138C2]">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusCircleIcon
                              className="h-6 w-6 text-[#4138C2]"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusCircleIcon
                              className="h-6 w-6 text-[#4138C2]"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <div
                        className="text-base leading-7 text-gray-600 pl-2"
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></div>
                      {/* <p className="text-base leading-7 text-gray-600">{faq.answer}</p> */}
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
